/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  News Add | Edit Page
   Summary : Render Utility component is responsible for rendering respective component 
   [e.g Overview | Content Management | Tagging]
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 21/Aug/2020 
  Author						: Aditya Tijare
================================================================ 
*/
import React, { useState, Suspense, useEffect, useRef } from "react";
import Tabs from "../../components/tabsComponent";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RenderUtility from "./component/utilities/renderUtility";
import FooterBar from "./component/utilities/footerBar";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { fetchNewsImageUrl, fetchSingleNews, saveOverview, updateOverview } from "../../../middleware/services/newsApi";
import { accessRightActionCheck, accessRightInterfaceCheck, getLoggedInUser, getProductVariant } from "../../../utilities";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../middleware/actions/utilityAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { newsOverviewValidationSchema } from "./component/overview/validationSchema";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../constants";
import { setIsPublishClickedState, setOverviewUpdateStatus } from "../../../middleware/actions/newsAction";

const Add = (props) => {
  const { accessRights: aR } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const newsId = props.computedMatch?.params?.newsId || "";
  const [saveNews, setSaveNews] = useState(false);
  const [newsTitle, setNewsTitle] = useState("");
  const isPublishClicked = useSelector((state) => state.newsState.clickStatus);
  const [newsMarketingArticle, setNewsMarketingArticle] = useState(true);
  const productVariant = getProductVariant();
  const user = getLoggedInUser();
  /*
        @Description : Default value for news overview form
    */
  const [newsInformation, setNewsInformation] = useState({
    newsTitle: "",
    newsSource: "",
    newsSourceURL: "",
    newsCompany: [],
    newsProduct: [],
    newsExpert: [],
    newsType: "News",
    newsCustomTags: [],
    newsPublishedDate: new Date() || "",
    newsMarketingArticle: "NO",
    newsPaywallSetting: "NO",
    newsIsBuzzingStory: "NO",
    newsAddToWidgets: "NO",
    newsInFocus: "NO",
  });
  const [newsImageSrc, setNewsImageSrc] = useState(undefined);
  const [imageError, setImageError] = useState("");
  const [newsExpertData, setNewsExpertData] = useState([]);
  const [nonDt, setNonDt] = useState([]);
  const [imageErrorFlag, setImageErrorFlag] = useState(true);
  const [content, setContent] = useState("");
  const [buzzingStory, setBuzzingStory] = useState("");
  const [addToWidgets, setAddToWidgets] = useState("");
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionBasicSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionBasicPublish = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.PUBLISH);
  const accessActionContentSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionTaxonomyAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionTaxonomyAddTags = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_TAGS);
  const accessActionTaxonomyDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionTaxonomyShowInProduct = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SHOW_IN_PRODUCT);

  /*
        @Description : Assign default value and bind validation schema
    */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: newsInformation,
    validationSchema: Yup.object().shape(newsOverviewValidationSchema),
  });

  const newsDefaultImageRef = useRef(null);
  let TABS_NAME = ["Basic", "Content"];
  productVariant === PRODUCT_VARIANT.INSIDER ? TABS_NAME.push("Taxonomy & Tags") : TABS_NAME.push("Taxonomy");

  const hashArr = {
    "#basic": "Basic",
    "#content": "Content",
    "#taxonomy": "Taxonomy",
  };
  /*
    @Description : Define breadcrumb for Add | Edit News
  */
  const breadCrumbLinks = [
    { linkUrl: "/news", linkName: aR.moduleName, linkActive: false },

    {
      linkUrl: newsId ? `/news/${newsId}/edit#basic` : `/news/add#basic`,
      linkName: newsId ? `${newsTitle}` : "Add New",
      linkActive: false,
    },
    {
      linkUrl: `/news/${newsId}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (newsId !== "") {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.select(0);
    }
  };

  /*Interface access */
  const interfaces = ["Basic", "Content", "Taxonomy"];
  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, interfaces);

  /*Interface functionality wise access */
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  /*
    @Description : Set active link on sidebar for news
  */
  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/news"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, props.location.hash, newsTitle]);

  /*
    @Description : Retrieve respective news data 
  */
  useEffect(() => {
    if (isPublishClicked) handleSave(true);
    if (newsId != "") {
      const response = fetchSingleNews(newsId, {
        fields: ["newsTitle"],
      });
      response
        .then((response) => {
          if (response.status == 200) {
            setNewsTitle(response.data.data.newsTitle);
          }
        })
        .catch((e) => {});
    }
  }, [newsId, isPublishClicked]);

  useEffect(() => {
    sessionStorage.removeItem("content-design");
  }, []);

  /*
        @Description : Fetch news data on edit
        1. set news data to respective fields
        2. Set image path for sending to child component
    */
  useEffect(() => {
    if (newsId) {
      fetchNews(newsId);
    }
  }, []);

  const fetchNews = (Id) => {
    if (Id !== "") {
      dispatch(showLoader());
      try {
        const response = fetchSingleNews(Id);
        response
          .then((response) => {
            dispatch(hideLoader());
            if (response.status === 200) {
              const data = response?.data?.data;
              let newsData = {
                newsTitle: data.newsTitle,
                newsSource: data.newsSource || "",
                newsSourceURL: data.newsSourceUrl || "",
                newsCompany: data.newsCompanyAssociated
                  ? [
                      ...data.newsCompanyAssociated.map((cC) => {
                        let cCV = { label: cC.companyName };
                        if (cC.companyId) {
                          cCV.value = cC.companyId;
                        } else {
                          cCV.value = cC.companyName;
                        }
                        return cCV;
                      }),
                    ]
                  : [],
                newsProduct: data.newsProductAssociated
                  ? [
                      ...data.newsProductAssociated.map((cC) => {
                        return {
                          label: cC.productName,
                          value: cC.productId,
                        };
                      }),
                    ]
                  : [],
                newsCustomTags:
                  response?.data?.data?.newsCustomTags?.map((cC) => {
                    let cCV = { label: cC.tagName, ...cC };
                    if (cC.tagId) {
                      cCV.value = cC.tagId;
                    } else {
                      cCV.value = cC.tagName;
                    }
                    return cCV;
                  }) || [],
                newsExpert:
                  response?.data?.data?.newsExpert?.map((v) => {
                    return {
                      label: `${v.userFname} ${v.userLname} (${v.userEmail}`,
                      value: v.userId,
                      id: v.userId,
                      userFname: v.userFname,
                      userLname: v.userLname,
                      userEmail: v.userEmail,
                    };
                  }) || [],
                newsType: data?.newsType,
                newsPublishedDate: data.newsPublishedDate,
                newsDocuments: data?.newsDocuments,
                newsMarketingArticle: data.newsMarketingArticle,
                newsPaywallSetting: data.newsPaywallSetting?.length > 0 ? "YES" : "NO",
                newsIsBuzzingStory: data?.newsIsBuzzingStory,
                newsAddToWidgets: data?.newsAddToWidgets,
                newsInFocus: data.newsInFocus && data?.newsInFocus,
              };
              if (data.newsProfileImage && data.newsProfileImage !== "") {
                setNewsImageSrc(fetchNewsImageUrl(response.data.data.newsProfileImage));
                newsData.newsThumbNail = response.data.data.newsProfileImage;
              }
              if (data.newsCategory && data.newsCategory.length > 0) {
                setNonDt(data.newsCategory);
              }
              setNewsInformation(newsData);
            } else {
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
          });
      } catch (e) {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  /*
    @Description : Change saveNews state for trigger news save event
    State Change trigger from footer component
  */
  const handleSave = async (newsSaveState) => {
    setSaveNews(newsSaveState);
    if (newsSaveState && imageError === "") {
      try {
        dispatch(showLoader());
        let flag = true;
        formik.values.newsProduct.forEach((c) => {
          if (c.label === c.value) {
            dispatch(actionError(`Custom products not allowed, please remove <b>${c.label}</b> product and try again`));
            handleSave(false);
            flag = false;
          }
        });
        let newsMarketingArticleSave = newsMarketingArticle === true ? "YES" : "NO";
        let newsIsBuzzingStory = buzzingStory === true ? "YES" : "NO";
        let newsAddToWidgets = addToWidgets === true ? "YES" : "NO";
        if (flag) {
          let newExpert = [];
          const structuredNewsExpert = newsExpertData?.map((ne) => {
            if (ne?.length > 1) {
              let test = ne?.map((e) => [e]);
              newExpert = [...newExpert, ...test];
              return undefined;
            } else {
              return ne;
            }
          });
          const properStructuredNewsExpert = structuredNewsExpert.filter((sne) => sne !== undefined);
          let final = [...properStructuredNewsExpert, ...newExpert];
          let newsExpertDataWithoutDuplicate = final;
          newsExpertDataWithoutDuplicate = [...new Set(newsExpertDataWithoutDuplicate)];
          let newsExpertIds = [];
          let newsPaywallProductPlatform = productVariant == PRODUCT_VARIANT.COGNITION ? "Signal V2" : `${productVariant} V2`;
          //newsInformation.newsExpert.forEach(ele => { newsExpertIds.push(ele.value) })
          formik.values.newsExpert.forEach((ele) => {
            newsExpertIds.push(ele.value);
          });

          newsExpertIds = [...new Set(newsExpertIds)];
          let overviewData = {
            newsTitle: formik.values.newsTitle,
            newsContent: "",
            newsSource: formik.values.newsSource,
            newsSourceUrl: formik.values.newsSourceURL,
            newsCustomTags: formik.values.newsCustomTags.map((v) => {
              // VALID_MONGO_ID.test(c)
              if (v.label === v.value) {
                return {
                  tag_name: v.label,
                  tagParentId: 0,
                  tagRoot: 0,
                };
              } else {
                return {
                  tag_id: v.value,
                  tag_name: v.label,
                  tagParentId: v.tagParentId,
                  tagRoot: v.tagRoot,
                };
              }
            }),

            newsCompanyAssociated: formik.values.newsCompany.map((c) => {
              // VALID_MONGO_ID.test(c)
              if (c.label === c.value) {
                return { companyName: c.value };
              } else {
                return {
                  companyName: c.label,
                  companyId: c.value,
                };
              }
            }),

            newsProductAssociated: formik.values.newsProduct.map((c) => {
              return {
                productName: c.label,
                productId: c.value,
              };
            }),

            // newsExpert: newsExpertData?.map((v) => {
            //     return formik?.values?.newsExpert.includes(v.id) && { userId: v.id, userFname: v.userFname, userLname: v.userLname };
            // }).filter(f => { return f !== false }) || [],

            newsExpert:
              newsExpertDataWithoutDuplicate?.map((v) => {
                if (v[0]?.id) {
                  if (newsExpertIds.includes(v[0].id)) {
                    newsExpertIds = newsExpertIds.filter((e) => e !== v[0].id);
                    return {
                      userId: v[0].id,
                      userFname: v[0].userFname,
                      userLname: v[0].userLname,
                      userEmail: v[0].userEmail,
                    };
                  }
                }
              }) || [],
            newsCategory: nonDt,
            newsType: formik.values.newsType,
            newsMarketingArticle: newsMarketingArticleSave,
            newsIsBuzzingStory: newsIsBuzzingStory,
            newsAddToWidgets: newsAddToWidgets,
            newsInFocus: formik.values.newsInFocus,
          };

          overviewData.newsExpert = overviewData.newsExpert.filter((f) => {
            return f !== undefined;
          });
          overviewData.newsExpert = overviewData.newsExpert.filter((f) => {
            return f !== null;
          });

          if (overviewData.newsExpert) overviewData.newsExpert = [...overviewData?.newsExpert];
          else overviewData.newsExpert = [];
          let saveOverviewData = new FormData();
          saveOverviewData.append("userId", user._id);
          saveOverviewData.append("newsCreatedByUserId", user._id);
          saveOverviewData.append("newsCreatedByUserName", user.user_fname + " " + user.user_lname);
          let newsDoc = formik.values.newsDocuments;
          for (let uKey in overviewData) {
            if (typeof overviewData[uKey] != "object") {
              if (overviewData[uKey]) saveOverviewData.append(uKey, overviewData[uKey]);
            } else {
              if (overviewData[uKey]) saveOverviewData.append(uKey, JSON.stringify(overviewData[uKey]));
            }
          }
          if (formik.values?.newsDocuments && formik.values?.newsDocuments.length) {
            for (const i of newsDoc) {
              if (!i.docFileName) {
                saveOverviewData.append("newsDocuments", i);
              }
            }
            // saveOverviewData.append("newsDocuments", formik.values.newsDocuments)
          }
          if (formik.values.newsPublishedDate) {
            let dateToConvert = new Date(formik.values.newsPublishedDate).toUTCString();
            saveOverviewData.append("newsPublishedDate", dateToConvert);
          }
          if (formik.values.newsPaywallSetting) {
            let newsPaywallSetting =
              formik.values.newsPaywallSetting == "YES"
                ? [
                    {
                      productVariant: productVariant,
                      productPlatform: newsPaywallProductPlatform,
                      taxonomyTagging: [],
                    },
                  ]
                : [];
            saveOverviewData.append("newsPaywallSetting", JSON.stringify(newsPaywallSetting));
          }
          if (
            newsDefaultImageRef.current.files.length &&
            (newsDefaultImageRef.current.files[0].type === "image/jpeg" || newsDefaultImageRef.current.files[0].type === "image/png")
          ) {
            saveOverviewData.append("newsProfileImage", newsDefaultImageRef.current.files[0]);
            setImageError("");
          } else if (newsId !== "" && newsImageSrc === undefined) {
            saveOverviewData.append("newsProfileImage", "");
          } else if (newsDefaultImageRef.current.files.length === 0 && !newsImageSrc && productVariant !== PRODUCT_VARIANT.COGNITION) {
            setImageError("Please upload image");
            dispatch(hideLoader());
            return;
          }

          formik.validateForm().then(async (res) => {
            if (Object.keys(res).length) {
              const touched = {};
              Object.keys(res).map((field) => {
                touched[field] = true;
                return touched;
              });
              const err =
                res &&
                Object.values(res).map((e) => {
                  return e;
                });
              if (err) dispatch(actionError("" + err.join(" . ")));
              formik.setFormikState({
                ...formik,
                touched: touched,
                errors: res,
              });
            } else {
              if (!imageError) {
                let response = "";
                if (content && newsId) {
                  let contentData = { newsContent: content };
                  for (let uKey in contentData) {
                    if (typeof contentData[uKey] != "object") {
                      if (contentData[uKey] && contentData[uKey] !== "") saveOverviewData.append(uKey, contentData[uKey]);
                    } else {
                      if (contentData[uKey] && contentData[uKey] !== "") saveOverviewData.append(uKey, JSON.stringify(contentData[uKey]));
                    }
                  }
                  sessionStorage.removeItem("content-design");
                }
                if (newsId === "") {
                  const productVariant = getProductVariant();
                  if (productVariant) saveOverviewData.append("productVariant", JSON.stringify([{ productName: productVariant, isLive: "NO" }]));
                  response = saveOverview(saveOverviewData);
                } else {
                  const productVariant = getProductVariant();
                  if (productVariant) saveOverviewData.append("productVariant", JSON.stringify([{ productName: productVariant }]));
                  response = updateOverview(newsId, saveOverviewData);
                }
                response
                  .then((response) => {
                    dispatch(hideLoader());
                    if (isPublishClicked) {
                      const res = newsId && fetchSingleNews(newsId);
                      res.then((res) => {
                        if (res.status === 200) {
                          if (res.data.data.newsContent !== "" && res.data.data.newsContent !== undefined) {
                            dispatch(setOverviewUpdateStatus(true));
                          } else {
                            dispatch(actionError("News content should not be blank to publish News."));
                          }
                          dispatch(setIsPublishClickedState(false));
                        }
                      });
                    } else {
                      if (!newsId) {
                        dispatch(actionSuccess("News Saved Successfully"));
                      } else {
                        dispatch(actionSuccess("News details updated successfully"));
                      }
                      newsId && fetchNews(newsId);
                    }
                    if (newsId === "") {
                      fetchNews(response.data.data.id);
                      handleRedirectOnAddNews(response.data.data.id);
                      newRecord();
                    }
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    dispatch(actionError(err?.data?.message || "Something Went Wrong...!"));
                  });
              } else dispatch(hideLoader());
              handleSave(false);
            }
          });
        }
      } catch (err) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  /*
    @Description : Redirect to edit page after adding news overview (Applicable only for add new news)
  */
  const handleRedirectOnAddNews = (addNewsId) => {
    history.push(`/news/${addNewsId}/edit#basic`);
  };
  const newRecord = () => {
    tabRef.current.enableTab(1, true);
    tabRef.current.enableTab(2, true);
    tabRef.current.select(0);
  };

  return (
    <div>
      <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
        <div>
          <Tabs
            tabsName={TABS_NAME}
            tabRef={tabRef}
            created={created}
            handleClick={() => {
              setSelectedIndex(tabRef.current.selectedItem);
            }}
            cssClass="newHeaderTabCss"
          >
            <Suspense
              fallback={
                <div className="text-center">
                  <h3>Loading...</h3>
                </div>
              }
            >
              <RenderUtility
                newRecord={newRecord}
                activeStepper={Object.keys(hashArr)[selectedIndex]}
                newsId={newsId}
                handleSave={handleSave}
                saveNews={saveNews}
                handleRedirectOnAddNews={handleRedirectOnAddNews}
                accessPermissionTaxonomy={accessActionTaxonomyAdd}
                accessPermissionTaxonomyDelete={accessActionTaxonomyDelete}
                accessActionTaxonomyAddTags={accessActionTaxonomyAddTags}
                accessActionTaxonomyShowInProduct={accessActionTaxonomyShowInProduct}
                selectedIndex={selectedIndex}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                setSaveNews={setSaveNews}
                fetchNews={fetchNews}
                setNewsImageSrc={setNewsImageSrc}
                newsImageSrc={newsImageSrc}
                newsInformation={newsInformation}
                newsDefaultImageRef={newsDefaultImageRef}
                setNewsMarketingArticle={setNewsMarketingArticle}
                newsMarketingArticle={newsMarketingArticle}
                imageError={imageError}
                setImageError={setImageError}
                newsExpertData={newsExpertData}
                setNewsExpertData={setNewsExpertData}
                nonDt={nonDt}
                setNonDt={setNonDt}
                imageErrorFlag={imageErrorFlag}
                setImageErrorFlag={setImageErrorFlag}
                formik={formik}
                content={content}
                setContent={setContent}
                setBuzzingStory={setBuzzingStory}
                buzzingStory={buzzingStory}
                addToWidgets={addToWidgets}
                setAddToWidgets={setAddToWidgets}
                accessRightInterfaceRights={accessRightInterfaceRights}
                hashArr={hashArr}
              />
            </Suspense>
          </Tabs>
        </div>
      </div>

      <FooterBar
        handleSave={handleSave}
        newsId={newsId}
        activeStepper={Object.keys(hashArr)[selectedIndex]}
        selectedIndex={selectedIndex}
        setCurrentTab={(selectedIndex) => {
          tabRef.current.select(selectedIndex);
          setSelectedIndex(selectedIndex);
        }}
        accessActionSave={accessActionBasicSave}
        accessActionContentSave={accessActionContentSave}
        accessActionBasicPublish={accessActionBasicPublish}
      />
    </div>
  );
};

export default Add;
