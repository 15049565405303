import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Accordion from "../../../../components/accordion";
import DocumentsBrochure from "../modals/overviewDocument";
import ImageComponent from "../modals/imageComponent";
import VideoComponent from "../modals/videoComponent";
import { actionError, actionSuccess } from "../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import ProductQcActions from "../../../dataQc/company/product/dataQcActions";
import { TextField, TextEditor, Dropdown, ToggleSwitchNew } from "../../../../components/CustomFormElements/syncFusionFormFields";
import productImage from "../../../../../assets/images/product.jpg";
import { productValidationSchema } from "../../../../helper/validationSchema/productValidationSchema";
import { fetchProductBasicById, fetchProductImageUrl } from "../../../../../middleware/services/productApi";
import { accessRightActionCheck, getProductVariant } from "../../../../../utilities";
import ProductDataPublicUI from "../../../dataPublishing/product/actionButton";
import RemarkListProduct from "../../../dataProcessing/remarkListProduct";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import { setProductDetails, setProductImage } from "../../../../../middleware/actions/productAction";
import { PRODUCT_LOGO_IMG_SIZE } from "config";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";

const ProductForm = (props) => {
  const { accessRights: aR } = props;
  const history = useHistory();
  const { onSaveData, onUpdateData, onSaveProcessingData, onFinalSaveProcessingData } = props;
  const [editData, setEditData] = useState({});
  const [clickSaveSubmit, setClickSaveSubmit] = useState(false);
  const [saveBtnText, setSaveBtnText] = useState(props.editData ? "Update" : "Save");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [oldData, setOldData] = useState(null);
  const [productId, setProductId] = useState(props.computedMatch?.params?.productId);
  const [productProfileImage, setProductProfileImage] = useState(undefined);
  const [imageError, setImageError] = useState("");
  const [interfaceActionAccessOverview, setInterfaceActionAccessOverview] = useState([]);

  const split_url = props?.location?.pathname.split("/");
  const productVariant = getProductVariant();
  const commonState = useSelector((state) => state.productState);
  const productDetails = commonState?.productDetails;
  const productImg = commonState?.productImage;
  const valuePrepositionDetails = commonState.valuePreposition;
  const productInformation = {
    productName: "",
    yearLaunched: "",
    licencingAvailablity: "",
    techMaturityLevel: "",
    domainClassification: "",
    productSourceURL: "",
    productDescription: "",
    productWhtUnique: "",
    productTechnology: "",
    objectiveProduct: "",
    productProfileImage: "",
    productPaywallSetting: "NO",
  };
  const formik = useFormik({
    initialValues: {
      ...productInformation,
    },
    validationSchema: Yup.object().shape(productValidationSchema),
  });

  /* Common access */
  const accessActionSave = interfaceActionAccessOverview.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionSubmit = interfaceActionAccessOverview.includes(COMMON_ACCESS_ACTION.SUBMIT);
  const accessActionFinalSubmit = interfaceActionAccessOverview.includes(COMMON_ACCESS_ACTION.FINAL_SUBMIT);
  const accessActionPublish = interfaceActionAccessOverview.includes(COMMON_ACCESS_ACTION.PUBLISH);

  const setFormikValues = async (response) => {
    let result = response.data;
    if (Object.keys(productDetails).length > 0) {
      result = productDetails;
      result.productFunctionality = productDetails.functionalitiesProduct;
      result.productWhatsUnique = productDetails.uniqueProduct;
      if (productImg != "") {
        result.productProfileImage = productImg.productImg;
        result.productImage = productImg.productImgFile;
      }
    }
    const oldData = {
      productName: result.productName || "",
      yearLaunched: result.yearLaunched ? result.yearLaunched : result.productLaunchYear || "",
      licencingAvailablity: result.licencingAvailablity ? result.licencingAvailablity : result.productLicensingAvailability || "",
      techMaturityLevel: result.techMaturityLevel ? result.techMaturityLevel : result.productTechnologyMaturityLevel || "",
      domainClassification: result.domainClassification ? result.domainClassification : result.productDomainClassification || "",
      productSourceURL: result.productSourceURL ? result.productSourceURL : result.productUrl || "",
      productDescription: result.productDescription || "",
      uniqueProduct: result.productWhatsUnique ? result.productWhatsUnique : "",
      functionalitiesProduct: result.productFunctionality ? result.productFunctionality : "",
      objectiveProduct: result.objectiveProduct ? result.objectiveProduct : result.productObjective || "",
      productProfileImage: result.productProfileImage || "",
      productPaywallSetting: result?.productPaywallSetting?.length > 0 ? result?.productPaywallSetting : "NO",
      productImage: result?.productImage ? result.productImage : "",
    };
    setOldData(oldData);
    formik.setValues(oldData);
  };
  const dispatch = useDispatch();
  const readProductProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      let productImage = {
        productImgFile: imageFile,
        productImg: e.target.result,
      };
      dispatch(setProductImage(productImage));
      productProfileImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };
  useEffect(() => {
    if (props.productId != undefined && props.productId.length === 24) setProductId(props.productId);
    let isMounted = false;
    if (props.editData && props.productId) {
      if (Object.keys(productDetails).length > 0) {
        setEditData(productDetails);
        setFormikValues(productDetails);
      } else {
        const response = fetchProductBasicById(props.productId);
        response
          .then((response2) => {
            if (response2.status === 200) {
              if (!isMounted) {
                if (response2.data.data) {
                  setEditData(response2.data);
                  setFormikValues(response2.data);
                }
              }
            } else {
              dispatch(actionError("Something Went Wrong"));
              throw Object.assign(new Error("SOMETHING WENT WRONG"), {
                code: 500,
              });
            }
          })
          .catch((e) => {
            dispatch(actionError("Something Went Wrong while processing."));
          });
      }
    } else {
      formik.resetForm();
    }
    return () => {};
  }, [props.editData, props.location.state]);

  useEffect(() => {
    if (clickSaveSubmit) handleSubmit();
  }, [clickSaveSubmit]);

  const saveProductData = async () => {
    const { values } = formik;
    let productPaywallProductPlatform = productVariant == "Cognition" ? "Signal V2" : `${productVariant} V2`;
    let productPaywallSettingData =
      formik.values?.productPaywallSetting == "YES"
        ? JSON.stringify([
            {
              productVariant: productVariant,
              productPlatform: productPaywallProductPlatform,
              taxonomyTagging: [],
            },
          ])
        : JSON.stringify([]);
    const productData = {
      companyId: props.companyId !== undefined ? props.companyId : "",
      productName: values.productName.trim(),
      productLaunchYear: values.yearLaunched,
      productLicensingAvailability: values.licencingAvailablity.trim(),
      productTechnologyMaturityLevel: values.techMaturityLevel,
      productDomainClassification: values.domainClassification.trim(),
      productUrl: values.productSourceURL.trim(),
      productDescription: values.productDescription,
      productWhatsUnique: values.uniqueProduct !== undefined ? values.uniqueProduct : "",
      productFunctionality: values.functionalitiesProduct !== undefined ? values.functionalitiesProduct : "",
      productObjective: values.objectiveProduct,
      productProfileImage: values.productProfileImage,
      productPaywallSetting: productPaywallSettingData,

      // source_created_by: {
      // 	user_id: props.authUser._id,
      // 	user_name: props.authUser.user_fname + " " + props.authUser.user_lname
      // },
      // source_updated_by: {
      // 	user_id: props.authUser._id,
      // 	user_name: props.authUser.user_fname + " " + props.authUser.user_lname
      // }
    };
    if (Object.keys(valuePrepositionDetails).length > 0) {
      productData.productValuePropositionTime = valuePrepositionDetails.timeReduction;
      productData.productValuePropositionCost = valuePrepositionDetails.costReduction;
      productData.productValuePropositionProductivity = valuePrepositionDetails.increasesProductivity;
      productData.productValuePropositionEfficiency = valuePrepositionDetails.increasesEficiency;
      productData.productValuePropositionGeneration = valuePrepositionDetails.revenueGenration;
      productData.productValuePropositionBenefits = valuePrepositionDetails.technicalBenefits;
      productData.productValuePropositionAesthetics = valuePrepositionDetails.enhancesAesthetics;
    }
    if (values.productImage) productData.productProfileImage = values.productImage;
    if (props.editData) {
      delete productData.companyId;
      productData.id = productId;
    }
    const response = props.editData ? onUpdateData(productData) : onSaveData(productData);
    setSaveBtnText(props.editData ? "UPDATING..." : "SAVING...");
    setBtnDisabled(true);
    response.then((result) => {
      setSaveBtnText(props.editData ? "Update" : "Save");
      setBtnDisabled(false);
      if (result.status === "success") {
        if (clickSaveSubmit) {
          dispatch(
            showAlertBox({
              okCallback: async () => {
                props.editData
                  ? onSaveProcessingData("overview")
                  : onSaveProcessingData("overview", {
                      productId: result.productId,
                    });
              },
              content: "Are you sure you want to submit?",
              okText: "Submit",
              cancelText: "Cancel",
              title: "dialogAlertCss",
            })
          );
          setClickSaveSubmit(false);
        }
        if (!props.editData) {
          split_url.pop();
          history.push(`${split_url.join("/") + "/" + result.productId}/edit#overview`, {
            companyId: result.companyId,
            productId: result.productId,
            editData: true,
          });
        }
      }
    });
  };

  const handleSubmit = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        dispatch(actionError(res["productName"]));
        dispatch(actionError(res["productDescription"]));
        formik.setFormikState({ ...formik, touched: touched, errors: res });
        setClickSaveSubmit(false);
      } else {
        if (imageError) {
          dispatch(actionError(imageError));
        } else {
          saveProductData();
        }
      }
    });
  };

  useEffect(() => {
    const options = {
      // damping:0.1,
      thumbMinSize: 20,
      alwaysShowTracks: true,
    };
    // setTimeout(() => {
    let classExist = document.querySelector(".gennx-content-wrapper");
    // }, 2000);
  }, []);
  useEffect(() => {
    dispatch(setProductDetails(formik.values));
  }, [formik.values]);

  const productProfileImageRef = useRef(null);
  const productProfileImageInputRef = useRef(null);

  const fetchProductImage = () => {
    if (productImg.productImg?.length > 1) {
      return productImg.productImg;
    } else {
      return fetchProductImageUrl(formik.values.productProfileImage);
    }
  };

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = props?.hashArr[props?.selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccessOverview(actionAccess);
  }, [props?.selectedIndex]);

  return (
    <>
      {/* <FormToolbar /> */}
      <div className="gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%" }}>
        <div className="mx-2">
          <Accordion heading="Product Detail & Description" stepCompleted={productId !== "" ? true : false} step={1}>
            <form>
              <div className="row px-3 pt-3">
                <div className="col-md-12 p-2 border bg-secondary text-white rounded-top">Product Detail &amp; Logo</div>
                <div className="col-md-12 border rounded-bottom">
                  <div className="p-2">
                    <div className="row">
                      <div className="col-md-2 col-sm-4">
                        <div className="image-holder pt-0">
                          <div className="d-flex align-item-center justify-content-center">
                            <img
                              src={formik.values.productProfileImage ? fetchProductImage() : productImage}
                              alt="Product"
                              width="200"
                              height="200"
                              ref={productProfileImageRef}
                            />
                          </div>
                          <div className="image-action mr-4" style={{ top: "0px", right: "18px" }}>
                            <i
                              className={`fa fa-trash mr-1 ${!formik.values.productProfileImage ? "d-none" : undefined}`}
                              aria-hidden="true"
                              onClick={() => {
                                dispatch(
                                  showAlertBox({
                                    okCallback: async () => {
                                      dispatch(actionSuccess("Image deleted successfully"));
                                      if (productProfileImageInputRef.current.files.length > 0) {
                                        formik.setValues({
                                          ...formik.values,
                                          productProfileImage: "",
                                        });
                                        productProfileImageInputRef.current.value = "";
                                      } else {
                                        setProductProfileImage(undefined);
                                        formik.setValues({
                                          ...formik.values,
                                          productProfileImage: "",
                                        });
                                        productProfileImageInputRef.current.value = "";
                                      }
                                    },
                                    content: "Are you sure you want to delete?",
                                    title: "dialogAlertCssWarning",
                                  })
                                );
                              }}
                            />
                            <span onClick={() => productProfileImageInputRef.current.click()}>
                              <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                            </span>
                          </div>
                          <input
                            type="file"
                            className="d-none"
                            accept=".jpg, .jpeg, .png"
                            id="productProfileImage"
                            name="productProfileImage"
                            ref={productProfileImageInputRef}
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                if (
                                  (e.target.files[0].type == "image/png" ||
                                    e.target.files[0].type == "image/jpg" ||
                                    e.target.files[0].type == "image/jpeg") &&
                                  e.target.files[0].size < PRODUCT_LOGO_IMG_SIZE
                                ) {
                                  setImageError("");
                                  setProductProfileImage(e.target.files[0].name);
                                  readProductProfileImage(e.target.files[0]);
                                  if (productProfileImageInputRef.current.files.length)
                                    formik.setValues({
                                      ...formik.values,
                                      productProfileImage: productProfileImageInputRef.current.files[0],
                                    });
                                } else {
                                  setProductProfileImage("");
                                  setImageError("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                                }
                              }
                            }}
                          />
                          <div
                            className="d-flex align-item-center justify-content-center text-danger"
                            style={{ display: imageError ? "block" : "none" }}
                            role="alert"
                          >
                            <small>{imageError}</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10 col-sm-8">
                        <div className="row pt-2">
                          <div className="col-md-6">
                            <TextField formik={formik} id="productName" name="productName" placeholder="Product Name *" required="required" />
                          </div>
                          <div className="col-md-6">
                            <TextField type="number" formik={formik} id="yearLaunched" name="yearLaunched" placeholder="Year Launched" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <TextField formik={formik} id="licencingAvailablity" name="licencingAvailablity" placeholder="Licensing Availability" />
                          </div>
                          <div className="col-md-6">
                            <Dropdown
                              formik={formik}
                              id="techMaturityLevel"
                              name="techMaturityLevel"
                              placeholder="Technology Maturity Level"
                              value={productDetails?.techMaturityLevel}
                              dataSource={[
                                "Concept Stage - Basic technology observed and reported",
                                "Concept Stage - Technology concept, product concept, and/or application formulated, feasibility quantified",
                                "Research Stage - Analytical and/or experimental proof of concept",
                                "Research Stage - Element validation of concepts for up-scaling in laboratory environment",
                                "Research Stage - Component validation in the relevant environment",
                                "Prototype Stage - Prototype demonstration in the relevant environment",
                                "Prototype Stage - System prototype demonstration in an operational environment",
                                "Prototype Stage - Actual system completed and 'qualified' through test and demonstration",
                                "Prototype Stage - Actual system 'proven' through successful mission operations",
                                "Commercialised",
                                "Widespread Adoption",
                              ]}
                              filtering={true}
                              // fields={{ text: "label", value: "value" }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <TextField formik={formik} id="domainClassification" name="domainClassification" placeholder="Domain/Classification" />
                          </div>
                          <div className="col-md-6">
                            <TextField formik={formik} id="productSourceURL" name="productSourceURL" placeholder="Product Source URL" />
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-6">
                            <ToggleSwitchNew
                              formik={formik}
                              required="required"
                              properties={{
                                id: "productPaywallSetting",
                                name: "productPaywallSetting",
                                label: "Enable Paywall",
                              }}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <label style={{ top: "20px", position: "relative" }}>About the Product</label> */}
                <div className="col-md-12 p-2 border bg-secondary text-white rounded-top mt-sm-3 ">About the Product *</div>
                <div className="col-md-12 border rounded-bottom">
                  <div className="row">
                    <div className="col-lg-12 nopadding">
                      <TextEditor
                        formik={formik}
                        properties={{
                          id: "productDescription",
                          name: "productDescription",
                          activeClass: "productDescription",
                          // label: "About",
                          config: {
                            ...ckEditorMinimalConfig,
                            editorplaceholder: "About the Product",
                            height: props.productId ? 250 : 180,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 p-2 border bg-secondary text-white rounded-top mt-sm-3">What is Unique about the Product?</div>
                <div className="col-md-12 border rounded-bottom">
                  <div className="row">
                    <div className="col-lg-12 nopadding">
                      <TextEditor
                        formik={formik}
                        properties={{
                          id: "uniqueProduct",
                          name: "uniqueProduct",
                          activeClass: "uniqueProduct",
                          // label: "About",
                          config: {
                            ...ckEditorMinimalConfig,
                            editorplaceholder: "What is Unique about the Product?",
                            height: props.productId ? 250 : 180,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 p-2 border bg-secondary text-white rounded-top mt-sm-3">
                  Describe the Core/key Functionalities of the Product
                </div>
                <div className="col-md-12 border rounded-bottom">
                  <div className="row">
                    <div className="col-lg-12 nopadding">
                      <TextEditor
                        formik={formik}
                        properties={{
                          id: "functionalitiesProduct",
                          name: "functionalitiesProduct",
                          activeClass: "functionalitiesProduct",
                          // label: "About",
                          config: {
                            ...ckEditorMinimalConfig,
                            editorplaceholder: "Describe the Core/key Functionalities of the Product",
                            height: props.productId ? 250 : 180,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 p-2 border bg-secondary text-white rounded-top mt-sm-3">
                  What was the original objective behind development of the Product
                </div>
                <div className="col-md-12 border rounded-bottom">
                  <div className="row">
                    <div className="col-lg-12 nopadding">
                      <TextEditor
                        formik={formik}
                        properties={{
                          id: "objectiveProduct",
                          name: "objectiveProduct",
                          activeClass: "objectiveProduct",
                          // label: "About",
                          config: {
                            ...ckEditorMinimalConfig,
                            editorplaceholder: "What was the original objective behind development of the Product",
                            height: props.productId ? 250 : 180,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-right float-right p-2 pt-3" style={{ marginLeft: "8px" }}>
                  {accessActionSave ? (
                    <button type="button" className="btn btn-primary cy-btn" onClick={handleSubmit} disabled={btnDisabled}>
                      {props.editData ? "Save" : saveBtnText}
                    </button>
                  ) : null}
                </div>
              </div>
            </form>
          </Accordion>
          {productId && editData ? (
            <>
              {/* - PRODUCT DETAIL & DESCRIPTION END */}
              {/* - DOCUMENTS / BROCHURE */}
              {<DocumentsBrochure productId={productId} accessRights={aR} />}
              {/* DOCUMENTS / BROCHURE END */}
              {/* - Video Pannel */}
              <VideoComponent productId={productId} accessRights={aR} />
              {/* - Video Pannel END */}
              {/* - Images Pannel */}
              <ImageComponent productId={productId} accessRights={aR} />
              {/* - Images Pannel END */}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {props.path == "/data-qc/company/:companyId/product/:productId/edit" ? (
        <ProductQcActions
          productId={props.productId}
          selectedIndex={props.selectedIndex}
          setCurrentTab={props.setCurrentTab}
          handleSubmit={() => {
            if (clickSaveSubmit) setClickSaveSubmit(false);
            setClickSaveSubmit(true);
          }}
          companyId={props.companyId}
        />
      ) : (
        <div className="form-submit-box">
          <div className="container-fluid nopadding">
            <div className="row">
              <div className="float-LEFT col-md-4 text-left">
                {props.productId !== "" && props.selectedIndex > -1 ? (
                  <span className="pl-4" style={{ marginLeft: "-3em" }}>
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-basic-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex + 1);
                        }}
                      >
                        Next
                      </button>
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="float-right col-md-8 text-right">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(props.backPath, {
                      companyId: props.companyId,
                    });
                  }}
                  className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                >
                  Cancel
                </button>

                {accessActionSubmit ? (
                  <button
                    type="button"
                    className="btn btn-primary cy-btn pl-2 pr-2 mr-3"
                    onClick={() => {
                      if (clickSaveSubmit) setClickSaveSubmit(false);
                      setClickSaveSubmit(true);
                    }}
                  >
                    Submit
                  </button>
                ) : null}
                {accessActionFinalSubmit ? (
                  <button
                    type="button"
                    className="btn btn-primary cy-btn pl-2 pr-2  mr-3"
                    onClick={() => {
                      onFinalSaveProcessingData();
                    }}
                  >
                    Final Submit
                  </button>
                ) : null}
                {accessActionPublish ? <ProductDataPublicUI productId={props.productId} /> : null}
                {props.path == "/processing/company/:companyId/product/:productId/edit" ? <RemarkListProduct productId={props.productId} /> : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductForm;
ProductForm.propTypes = {
  // data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // history: PropTypes.object,
  location: PropTypes.object.isRequired,
  productId: PropTypes.string,
  editData: PropTypes.bool.isRequired,
  onSaveProcessingData: PropTypes.func.isRequired,
  onFinalSaveProcessingData: PropTypes.func.isRequired,
  onSaveData: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired,
};
