/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React, { useEffect, useState, useRef, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import {
  getBriefcaseCategoryList,
  getSpecificCategoryBriefcase,
  deleteBriefcase,
  deleteCategory,
  getAllBriefcaseCategoryList,
} from "../../../middleware/services/myBriefcase";
import ModalNewFolder from "../../components/ModalNewFolder/ModalNewFolder";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import ModalRenameFolder from "../../components/ModalRenameFolder/ModalRenameFolder";
import SortDropdown from "../../components/sortingDropdown/sortingDropdown";
import EmptyData from "../../components/emptyData/EmptyData";
import PinkButton from "../../components/pinkButton/PinkButton";
import SidebarModal from "../../components/sidebar-modal";
import classes from "./myBriefcase.module.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import withScrolling from "react-dnd-scrolling";
import MoveToBriefcase from "../../components/moveBriefcase/moveBriefcase";
import ContainerSidebar from "./ContainerSidebarCard.js";
import { accessRightActionCheck, getLoggedInUser } from "utilities";
import {
  updateBriefcaseCategoryId,
  updateBriefcaseCount,
  updateCategorySkipCount,
  applyCategoryFilter,
  updateNewFolderId,
  resetBriefcaseListing,
  getCategoryListing,
  getAllBriefcaseCategory,
  updateCategoryBriefcaseListing,
  deleteBriefcaseCategory,
  resetBriefcaseState,
} from "../../../middleware/actions/myBriefcaseAction";
import { actionError, actionSuccess, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { downloadMethod } from "../../../middleware/services/downloadPdfService";
import { CMS_API_URL } from "../../../config";
import FilterContentTypeDropdown from "../../components/filterContentTypeDropdown/filterContentTypeDropdown";
// import SortingBriefcaseDropdown from "../../components/sortingBriefcaseDropdown/sortingBriefcaseDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/fontawesome-free-solid";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import Items from "./Items";
import { COMMON_ACCESS_ACTION } from "../../../constants";

const ContainerCard = lazy(() => import("./ContainerCard.js"));
const ScrollingComponent = withScrolling("div");

const MyBriefcase = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [folderList, setFolderList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [renameDeleteId, setRenameDeleteId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameFolderModal, setShowRenameFolderModal] = useState(false);
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showDownloadOption, setShowDownloadOption] = useState(false);
  const [showSiderBar, setShowSiderBar] = useState(false);
  const [activePPage, setActivePPage] = useState(1);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  // ** Delete moldel state
  const [showBriefcaseDeleteModal, setShowBriefcaseDeleteModal] = useState(false);
  const [showBriefcaseMoveModal, setShowBriefcaseMoveModal] = useState(false);
  const [categorySortValue, setCategorySortValue] = useState("Newest");
  const [briefcaseFilterValue, setBriefcaseFilterValue] = useState("All");
  const [briefcaseSortValue, setBriefcaseSortValue] = useState("SortBySequence");
  const [briefcaseCardsValue, setBriefcaseCardsValue] = useState([]);
  const [lodingBriefcase, setLodingBriefcase] = useState(true);
  const user = getLoggedInUser();
  const productVariant = localStorage.getItem("productVariant");
  const categoryId = useSelector((state) => state["myBriefcaseState"]["categoryId"]);
  const categoryList = useSelector((state) => state["myBriefcaseState"]["categoryListing"]);
  const allBriefcaseCategoryListing = useSelector((state) => state["myBriefcaseState"]["allBriefcaseCategoryListing"]);
  const categoryBriefcase = useSelector((state) => state["myBriefcaseState"]["categoryBriefcaseListing"]);
  const newFolderId = useSelector((state) => state["myBriefcaseState"]["newFolderId"]);

  /* Common access */
  const accessActionMove = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.MOVE);
  const accessActionDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionDownload = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DOWNLOAD);

  const handleRenameFolderShow = () => setShowRenameFolderModal(true);
  const handleRenameFolderClose = () => setShowRenameFolderModal(false);

  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  // ** Delete Model functions
  const handleBriefcaseDeleteShow = () => setShowBriefcaseDeleteModal(true);
  const handleBriefcaseDeleteClose = () => setShowBriefcaseDeleteModal(false);
  const handleBriefcaseMoveShow = () => setShowBriefcaseMoveModal(true);
  const handleBriefcaseMoveClose = () => {
    setShowBriefcaseMoveModal(false);
  };

  const handleNewFolderClose = () => setShowNewFolderModal(false);
  const handleNewFolderShow = () => setShowNewFolderModal(true);

  // show download option
  const downloadOptionHandler = () => {
    setShowDownloadOption(!showDownloadOption);
  };

  const updateCategoryId = (id) => {
    dispatch(updateBriefcaseCategoryId(id));
    setSelectedDoc([]);
  };

  const menuClickHandler = (id) => {
    setShowMenu(true);
    setRenameDeleteId(id);
  };

  const changeFilter = (event) => {
    let filterValue = event.target.value;
    setCategorySortValue(filterValue);
    if (filterValue === "Newest") {
      dispatch(applyCategoryFilter("categoryCreatedDate:desc"));
      getBriefcaseSortFolderList("categoryCreatedDate:desc");
    }
    if (filterValue === "Oldest") {
      dispatch(applyCategoryFilter("categoryCreatedDate:asc"));
      getBriefcaseSortFolderList("categoryCreatedDate:asc");
    }
    if (filterValue === "Ascending") {
      dispatch(applyCategoryFilter("categoryName:asc"));
      getBriefcaseSortFolderList("categoryName:asc");
    }
    if (filterValue === "Descending") {
      dispatch(applyCategoryFilter("categoryName:desc"));
      getBriefcaseSortFolderList("categoryName:desc");
    }
  };

  const setActivePage = (event) => {
    dispatch(updateCategorySkipCount(event));
  };

  const updateCategoryListPage = (num) => {
    dispatch(updateCategorySkipCount(num));
    dispatch(updateBriefcaseCategoryId(""));
  };

  const handlePageClick = (event) => {
    setSelectedDoc([]);
    dispatch(updateNewFolderId(""));
    setActivePPage(event);
    setActivePage(event);
    updateCategoryListPage(event);
    getBriefcaseFolderListAfterAction();
  };

  // const changeBriefcaseSorting = (event) => {
  //   let sortValue = event.target.value;
  //   setBriefcaseSortValue(sortValue);
  //   if (sortValue === "SortBySequence") {
  //     setBriefcaseFilterValue("All");
  //     getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
  //   } else {
  //     let result = sortAndFilterData(sortValue, briefcaseFilterValue, categoryBriefcase?.list);
  //     setBriefcaseCardsValue(result || []);
  //   }
  // };

  const changeBriefcaseFilter = (event) => {
    let filterValue = event.target.value;
    setBriefcaseFilterValue(filterValue);
    let result = sortAndFilterData(briefcaseSortValue, filterValue, categoryBriefcase?.list);
    setBriefcaseCardsValue(result || []);
  };

  function sortAndFilterData(sortValue, filterValue, data) {
    // const isAscending = sortValue === "Newest";
    // data.sort((a, b) => {
    //   const dateA = new Date(a.presentationCreatedDate);
    //   const dateB = new Date(b.presentationCreatedDate);
    //   return isAscending ? dateA - dateB : dateB - dateA;
    // });

    if (filterValue === "All") {
      return data;
    } else {
      const filteredData = data.filter((item) => {
        return item.presentationContentType === filterValue;
      });
      return filteredData;
    }
  }

  const getCategoryBriefcase = async (briefcaseCategoryId, sort) => {
    dispatch(showLoader());
    setLodingBriefcase(true);
    await getSpecificCategoryBriefcase(user?._id, briefcaseCategoryId, sort)
      .then((res) => {
        setBriefcaseCardsValue(res.data || []);
        dispatch(
          updateCategoryBriefcaseListing({
            list: res.data,
            totalCount: res.totalCount,
          })
        );
        setBriefcaseFilterValue("All");
        setBriefcaseSortValue("SortBySequence");
        dispatch(hideLoader());
        setLodingBriefcase(false);
      })
      .catch((error) => {
        dispatch(hideLoader());
        setLodingBriefcase(false);
        console.log("error  category Briefcase listing >>>", error);
      });
  };

  const getAllCategory = () => {
    getAllBriefcaseCategoryList(user?._id, productVariant)
      .then((res) => {
        dispatch(
          getAllBriefcaseCategory({
            res,
            totalCount: res.totalCount,
          })
        );
      })
      .catch((error) => {
        console.log("error  category listing >>>", error);
      });
  };

  const getBriefcaseFolderList = async () => {
    await getBriefcaseCategoryList(user?._id, categoryList.params, productVariant)
      .then((res) => {
        setFolderList(res.data || []);
        dispatch(
          getCategoryListing({
            res,
            totalCount: res.totalCount,
          })
        );
        if (!res?.data?.length) setLodingBriefcase(false);
      })
      .catch((error) => {
        setLodingBriefcase(false);
        console.log("error  category listing >>>", error);
      });
  };

  const getBriefcaseFolderListAfterAction = async () => {
    await getBriefcaseCategoryList(user?._id, categoryList.params, productVariant)
      .then((res) => {
        setFolderList(res.data || []);
        dispatch(updateBriefcaseCategoryId(res?.data[0]?.id));
        if (res?.data[0]?.id) getCategoryBriefcase(res?.data[0]?.id, `presentationSeqNo:asc`);
        dispatch(
          getCategoryListing({
            res,
            totalCount: res.totalCount,
          })
        );
        if (!res?.data?.length) setLodingBriefcase(false);
      })
      .catch((error) => {
        setLodingBriefcase(false);
        console.log("error  category listing >>>", error);
      });
  };

  const getBriefcaseSortFolderList = async (sort) => {
    await getBriefcaseCategoryList(user?._id, { skip: 0, limit: 10, sort: sort }, productVariant)
      .then((res) => {
        setSelectedDoc([]);
        setBriefcaseFilterValue("All");
        setBriefcaseSortValue("SortBySequence");
        setBriefcaseCardsValue([]);
        setFolderList(res.data || []);
        dispatch(updateBriefcaseCategoryId(res?.data[0]?.id));
        dispatch(
          getCategoryListing({
            res,
            totalCount: res.totalCount,
          })
        );
        if (!res?.data?.length) setLodingBriefcase(false);
      })
      .catch((error) => {
        setLodingBriefcase(false);
        console.log("error  category listing >>>", error);
      });
  };

  // function to close the menu modal on clicking outside
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
          setShowDownloadOption(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  // Download Briefcase in Pdf file
  const downloadSelectedDocsPdf = async (fileContents, categoryName) => {
    try {
      dispatch(showLoader());
      const response = await downloadMethod(`${CMS_API_URL}/export-to-pdf`, {
        zipFileName: categoryName,
        fileContents: fileContents,
        pdfContents: [],
      });
      let fileName = categoryName ? `${categoryName}.pdf` : `briefcase.pdf`;

      let blob = new Blob([response], { type: "application/pdf" });

      if (!blob.size) {
        dispatch(actionError("Unable to export the selected briefcase(s). Please try again."));
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    } catch (error) {
      let msg = "Unable to export the selected briefcase(s). Please try again by selecting fewer briefcase(s) at a time.";
      console.error("Error during download:", error);
      dispatch(actionError(msg));
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickDownload = (isMultipleDownload) => {
    let categoryName = categoryList?.list?.find((el) => el.id === categoryId)?.categoryName;
    if (isMultipleDownload) {
      let list = [];
      selectedDoc.forEach((res) => {
        if (res.presentationEntityPageId) {
          list.push({
            entityId: res.presentationEntityId,
            pageId: res.presentationEntityPageId,
            pageHtmlContent: res.presentationEntityPageHtmlContent,
            pageTitle: res.presentationTitle,
            pageContentType: res.presentationContentType,
            pageCreatedDate: res.presentationCreatedDate,
          });
        }
      });

      let fileContents = list?.map((res) => ({
        fileName: res.pageTitle,
        htmlContent: res.pageHtmlContent,
        contentType: res.pageContentType,
        createdDate: res.pageCreatedDate,
      }));

      if (fileContents?.length > 0) {
        downloadSelectedDocsPdf(fileContents, categoryName);
        setSelectedDoc([]);
        setShowSiderBar(false);
      }
    }
  };

  // Download Briefcase in PPT
  const downloadSelectedPpt = async (fileContents, categoryName) => {
    try {
      dispatch(showLoader());
      const response = await downloadMethod(`${CMS_API_URL}/export-to-ppt`, {
        zipFileName: categoryName,
        fileContents: fileContents,
        pdfContents: [],
      });
      let fileName = categoryName ? `${categoryName}.pptx` : `briefcase.pptx`;

      let blob = new Blob([response], { type: "application/pptx" });
      if (!blob.size) {
        dispatch(actionError("Unable to export the selected briefcase(s). Please try again."));
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    } catch (error) {
      let msg = "Unable to export the selected briefcase(s). Please try again by selecting fewer briefcase(s) at a time.";
      console.error("Error during download:", error);
      dispatch(actionError(msg));
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickDownloadPpt = (isMultipleDownload) => {
    let categoryName = categoryList?.list?.find((el) => el.id === categoryId)?.categoryName;
    if (isMultipleDownload) {
      let list = [];
      selectedDoc.forEach((res) => {
        if (res.presentationEntityPageId) {
          list.push({
            entityId: res.presentationEntityId,
            pageId: res.presentationEntityPageId,
            pageHtmlContent: res.presentationEntityPageHtmlContent,
            pageTitle: res.presentationTitle,
            pageContentType: res.presentationContentType,
            pageCreatedDate: res.presentationCreatedDate,
          });
        }
      });

      let fileContents = list?.map((res) => ({
        fileName: res.pageTitle,
        htmlContent: res.pageHtmlContent,
        contentType: res.pageContentType,
        createdDate: res.pageCreatedDate,
      }));

      if (fileContents?.length > 0) {
        downloadSelectedPpt(fileContents, categoryName);
        setSelectedDoc([]);
        setShowSiderBar(false);
      }
    }
  };

  const onClickDelete = (isMultipleDelete) => {
    setSelectedDoc([]);
    if (isMultipleDelete) {
      dispatch(showLoader());
      let list = selectedDoc.map((res) => res.id);
      deleteBriefcase(user?._id, list)
        .then(() => {
          dispatch(actionSuccess("Briefcase deleted successfully."));
          getBriefcaseFolderList();
          getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
          sortAndFilterData(briefcaseSortValue, briefcaseFilterValue, briefcaseCardsValue);
          dispatch(hideLoader());
        })
        .catch((error) => {
          dispatch(actionError(error.response.data.message));
          dispatch(hideLoader());
        });
    }
    handleBriefcaseDeleteClose();
  };

  const handleIdValue = (id) => {
    return selectedDoc.some((el) => el.id === id);
  };

  const onCheckHandler = (item) => {
    const found = selectedDoc.some((el) => el.id === item.id);
    if (found) {
      const newArray = selectedDoc.filter((el) => el.id !== item.id);
      setSelectedDoc(newArray);
    } else {
      setSelectedDoc([...selectedDoc, item]);
    }
  };

  const selectAllBriefcase = () => {
    setShowSiderBar(false);
    setSelectedDoc([...briefcaseCardsValue]);
  };

  const DeSelectAllBriefcase = () => {
    setShowSiderBar(false);
    setSelectedDoc([]);
  };

  const resetBriefcase = () => {
    getBriefcaseFolderList();
    getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
  };

  useEffect(() => {
    if (!user?._id) return;
    getBriefcaseFolderList();
  }, [categoryList.params]);

  useEffect(() => {
    dispatch(resetBriefcaseState());
    dispatch(setBreadCrumb());
    if (user?._id) {
      getAllCategory();
    }
  }, []);

  useEffect(() => {
    if (categoryId && categoryId !== "") {
      getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
    }
  }, [categoryId, user?._id]);

  // auto scroll function to visible selected news section
  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(`list${categoryId}`);
      if (element !== null) {
        element.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [categoryId]);

  useEffect(() => {
    if (categoryId === "") {
      dispatch(resetBriefcaseListing());
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    if (activePPage > 1 && !categoryList.list.length) {
      dispatch(updateCategorySkipCount(activePPage - 1));
      dispatch(updateBriefcaseCategoryId(""));
      setActivePPage(activePPage - 1);
    }
  }, [activePPage, categoryList.list, dispatch]);

  useEffect(() => {
    if (renameDeleteId && renameDeleteId !== categoryId) return;
    if (newFolderId === "" && categoryList.list && categoryList.list.length && categoryList.list.filter((e) => e.id === categoryId).length === 0) {
      dispatch(updateBriefcaseCategoryId(categoryList.list[0].id));
    }
  }, [categoryId, categoryList.list, dispatch, newFolderId]);

  let categoryPage = categoryList.params.page === undefined ? 1 : categoryList.params.page;

  useEffect(() => {
    if (categoryBriefcase.list.length && categoryBriefcase.list[0].presentationCategoryId === categoryId) {
      const selectedFolder = categoryList.list.filter((i) => i.id === categoryId);

      if (selectedFolder.length && selectedFolder[0].presentationCount !== categoryBriefcase.list.length) {
        dispatch(updateBriefcaseCount(categoryBriefcase.list.length));
      }
    }
  }, [categoryId, categoryList.list, categoryBriefcase.list, dispatch]);

  useEffect(() => {
    if (categoryBriefcase.list.length === 0) {
      dispatch(updateBriefcaseCount(categoryBriefcase.list.length));
    }
  }, [categoryBriefcase.list.length, dispatch]);

  /* Interface actions */
  useEffect(() => {
    let actionAccess = accessRightActionCheck(aR.moduleName, aR.interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden", padding: "16px", position: "relative" }} className="gennx-envelope">
      <p style={{ fontSize: "14px", color: "#B7B7BB" }}>My Briefcase</p>
      <div className={classes.bookMarkMain}>
        <div className={classes.bookMarkRecent}>
          <div className={classes.sort_content}>
            <p onClick={handleNewFolderShow}>
              <img src="/assets/add-folder.svg" alt="folder_icon"></img> New Folder
            </p>
            <SortDropdown changeFilter={changeFilter} showText={false} value={categorySortValue} />
          </div>
          <div className={classes.listManDiv} id="bookmarkMobileList">
            {categoryList?.list?.length > 0 && (
              <Items
                folderList={folderList}
                categoryId={categoryId}
                updateCategoryId={updateCategoryId}
                setSelectedDoc={setSelectedDoc}
                setBriefcaseFilterValue={setBriefcaseFilterValue}
                setBriefcaseSortValue={setBriefcaseSortValue}
                setBriefcaseCardsValue={setBriefcaseCardsValue}
                renameDeleteId={renameDeleteId}
                showMenu={showMenu}
                menuClickHandler={menuClickHandler}
                handleRenameFolderShow={handleRenameFolderShow}
                handleDeleteShow={handleDeleteShow}
                setShowMenu={setShowMenu}
              />
            )}

            {allBriefcaseCategoryListing.length > 0 && (
              <div className={classes.paginationMain}>
                <p className={classes.paginationCountP}>
                  {categoryPage * 10 - 9} -{" "}
                  {categoryPage * 10 > allBriefcaseCategoryListing.length ? allBriefcaseCategoryListing.length : categoryPage * 10} of{" "}
                  {allBriefcaseCategoryListing.length}
                </p>

                <Pagination
                  innerClass={`${classes.paginationButtonDiv}`}
                  activeClass={classes.activePageClass}
                  activePage={categoryPage}
                  itemsCountPerPage={10}
                  totalItemsCount={allBriefcaseCategoryListing.length}
                  pageRangeDisplayed={3}
                  onChange={handlePageClick}
                  hideFirstLastPages={true}
                  prevPageText={<img src="/assets/pagination-arrow.svg" alt="prevIcon" />}
                  nextPageText={<img src="/assets/pagination-arrow.svg" alt="nextIcon" />}
                />
              </div>
            )}
          </div>
        </div>

        <div className={classes.bookMarkFilters}>
          <div className={classes.bookMarkFiltersInside}>
            {!selectedDoc.length && categoryList?.list?.length > 0 && categoryBriefcase?.list?.length > 0 && (
              <div className={classes.downloadBoxRight} style={{ marginBottom: window.innerWidth <= 1024 ? "16px" : "" }}>
                <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginRight: "24px" }}>
                  {/* <div style={{ display: "flex", alignItems: "center", marginLeft: "24px" }}>
                    <p style={{ fontSize: "14px", marginRight: "16px", color: "#B7B7BB" }}>Sorting</p>
                    <SortingBriefcaseDropdown changeFilter={changeBriefcaseSorting} value={briefcaseSortValue} />
                  </div> */}
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "24px" }}>
                    <p style={{ fontSize: "14px", marginRight: "16px", color: "#B7B7BB" }}>Filter By Content Type</p>
                    <FilterContentTypeDropdown
                      changeFilter={changeBriefcaseFilter}
                      value={briefcaseFilterValue}
                      // isDisabled={briefcaseSortValue === "SortBySequence" ? true : false}
                    />
                  </div>
                </div>
                {briefcaseCardsValue && briefcaseCardsValue?.length > 0 && (
                  <div className={classes.downloadDelete}>
                    <PinkButton onClick={() => selectAllBriefcase()} className={classes.pinkButton}>
                      Select All
                    </PinkButton>
                  </div>
                )}
                <div className={classes.downloadDelete} style={{ marginLeft: "24px" }}>
                  <PinkButton onClick={() => resetBriefcase()} className={classes.pinkButton}>
                    Reset Filters
                  </PinkButton>
                </div>
              </div>
            )}
            {selectedDoc.length > 0 && (
              <div className={classes.downloadDelete}>
                {window.innerWidth > 1024 && (
                  <p className={classes.selectedText} style={{ marginBottom: window.innerWidth <= 1024 ? "16px" : "" }}>
                    {selectedDoc.length} selected
                  </p>
                )}

                <div className={classes.downloadBoxRight} style={{ marginBottom: window.innerWidth <= 1024 ? "0px" : "" }}>
                  <div className={classes.selectAllBox}>
                    {selectedDoc.length === briefcaseCardsValue?.length ? (
                      <PinkButton onClick={() => DeSelectAllBriefcase()} className={classes.pinkButton}>
                        Deselect All
                      </PinkButton>
                    ) : (
                      <PinkButton onClick={() => selectAllBriefcase()} className={classes.pinkButton}>
                        Select All
                      </PinkButton>
                    )}
                  </div>
                  {accessActionMove && (
                    <div className={classes.moveText} onClick={handleBriefcaseMoveShow}>
                      <p>
                        <img src="/assets/folder-move.svg" alt="move"></img>
                        Move
                      </p>
                    </div>
                  )}
                  {accessActionDelete && (
                    <div className={classes.deleteText} onClick={handleBriefcaseDeleteShow}>
                      <p>
                        <img src="/assets/delete.svg" alt="delete"></img>
                        Delete
                      </p>
                    </div>
                  )}
                  {accessActionDownload && (
                    <div className={classes.downloadBox} onClick={() => setShowSiderBar(true)}>
                      <p className={classes.downloadBtnP}>
                        <img src="/assets/download.svg" alt="download"></img>
                        Download
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {window.innerWidth <= 1024 && selectedDoc.length > 0 && (
              <p className={classes.selectedText} style={{ marginBottom: window.innerWidth <= 1024 ? "16px" : "" }}>
                {selectedDoc.length} selected
              </p>
            )}
            {categoryList?.list?.length > 0 && categoryBriefcase && categoryBriefcase?.list?.length > 0 && (
              <DndProvider backend={window.innerWidth > 1024 ? HTML5Backend : TouchBackend} options={{ enableMouseEvents: true }}>
                <ScrollingComponent className={classes.drag_scroll_bar}>
                  <ContainerCard
                    categoryBriefcase={briefcaseCardsValue || []}
                    onCheckHandler={onCheckHandler}
                    handleIdValue={handleIdValue}
                    selectedDoc={selectedDoc}
                    categoryId={categoryId}
                    userId={user?._id}
                    getCategoryBriefcase={getCategoryBriefcase}
                    briefcaseSortValue={briefcaseSortValue}
                    briefcaseFilterValue={briefcaseFilterValue}
                    lodingBriefcase={lodingBriefcase}
                  />
                </ScrollingComponent>
              </DndProvider>
            )}
            {!lodingBriefcase && (!categoryBriefcase?.list?.length || !categoryList?.list?.length) && (
              <EmptyData msg="You don't have any briefcase." />
            )}
          </div>
        </div>
      </div>

      <ModalDelete
        show={showDeleteModal}
        onHide={handleDeleteClose}
        onDeleteClick={() => {
          deleteCategory(user._id, renameDeleteId)
            .then(() => {
              dispatch(actionSuccess("Briefcase folder deleted successfully."));
              dispatch(updateCategorySkipCount(activePPage));
              dispatch(deleteBriefcaseCategory(categoryId));
              getAllCategory();
              if (renameDeleteId === categoryId) getBriefcaseFolderListAfterAction();
              else getBriefcaseFolderList();
            })
            .catch((error) => {
              dispatch(actionError(error.response.data.message));
            });
          handleDeleteClose();
        }}
        text="All the briefcase you have added in this folder will be removed. Are you sure, you want to delete folder?"
        saveButtonText="Delete"
      />
      <ModalNewFolder
        folderType="briefcase"
        modalTitle="New Folder"
        show={showNewFolderModal}
        showNewFolderModal={showNewFolderModal}
        onHide={handleNewFolderClose}
        setActivePage={setActivePage}
        setActivePPage={setActivePPage}
        setCategorySortValue={setCategorySortValue}
        getBriefcaseSortFolderList={getBriefcaseSortFolderList}
      />
      <ModalRenameFolder
        renameType="briefcase"
        modalTitle="Rename Folder"
        show={showRenameFolderModal}
        onHide={handleRenameFolderClose}
        renameDeleteId={renameDeleteId}
        getBriefcaseFolderList={getBriefcaseFolderList}
      />
      <ModalDelete
        show={showBriefcaseDeleteModal}
        onHide={handleBriefcaseDeleteClose}
        onDeleteClick={() => onClickDelete(true)}
        text="All the briefcase you have added will be removed. Are you sure, you want to delete briefcase?"
        saveButtonText="Delete"
      />
      <MoveToBriefcase
        showBriefcaseModal={showBriefcaseMoveModal}
        hideBriefcaseModal={handleBriefcaseMoveClose}
        setShow={setShowBriefcaseMoveModal}
        selectedBriefcase={selectedDoc}
        briefcaseCategoryId={categoryId}
        setSelectedDoc={setSelectedDoc}
        getCategoryAllBriefcase={getCategoryBriefcase}
        getBriefcaseFolderList={getBriefcaseFolderList}
      />
      {showSiderBar && selectedDoc?.length > 0 && (
        <SidebarModal
          style={{ width: "400px" }}
          header={`Download (${selectedDoc?.length})`}
          backDrop
          footer={
            <div className="pt-2 pb-2">
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px" }}
                onClick={() => {
                  setShowSiderBar(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ml-3 mr-3"
                style={{ fontSize: "14px", paddingLeft: "24px", paddingRight: "24px" }}
                onClick={downloadOptionHandler}
              >
                <div className={classes.downloadOptionsBtn}>
                  Download <FontAwesomeIcon icon={faAngleDown} width={20} height={20} style={{ marginLeft: "4px" }} />
                </div>
                {showDownloadOption && (
                  <div className={classes.download_options} ref={wrapperRef}>
                    <div className={classes.select_btn} onClick={() => onClickDownload(true)}>
                      <p>PDF File</p>
                    </div>
                    <div className={classes.select_btn} onClick={() => onClickDownloadPpt(true)}>
                      <p>PPT File </p>
                    </div>
                  </div>
                )}
              </button>
            </div>
          }
        >
          <DndProvider backend={window.innerWidth > 1024 ? HTML5Backend : TouchBackend} options={{ enableMouseEvents: true }}>
            <ScrollingComponent
              className={classes.drag_side_scroll_bar}
              style={{ overflow: "auto", maxHeight: "100vh", width: "100%", marginTop: "-22px" }}
            >
              <ContainerSidebar selectedDoc={selectedDoc} setSelectedDoc={setSelectedDoc} />
            </ScrollingComponent>
          </DndProvider>
        </SidebarModal>
      )}
    </div>
  );
};

export default MyBriefcase;
